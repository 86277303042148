html, body, #root, #root>div.login-form, #root>div.setting-up-screen {
  height: 100%;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.scrollable {
  display: block!important;
  overflow: auto;
  height: 200px;
}

.content {
  min-height: calc(100vh - 136px);
}

#uid-helper-text {
  width: max-content;
}

#gid-helper-text {
  width: max-content;
}

@media only screen and (max-width: 635px) {
  #uid-helper-text {
    width: inherit;
  }

  #gid-helper-text {
    width: inherit;
  }
}
